import React from "react";
import { compose } from "recompose";
import Layout from "../../components/Layout";
import {
  withAuthorization,
  withEmailVerification,
} from "../../components/Session";
import { UserList } from "../../components/Users";
import PromoCodes from "../../components/Admin/PromoCodes";
import * as ROLES from "../../constants/roles";
import { navigate } from "gatsby";
import * as ROUTES from "../../constants/routes";

function AdminPageBase() {
  const [showUsers, setShowUsers] = React.useState(true);
  const [showPromoCodes, setShowPromoCodes] = React.useState(false);
  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-12">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              Admin Panel
            </h1>
            <button
              onClick={() => {
                setShowPromoCodes(!showPromoCodes);
                setShowUsers(!showUsers);
              }}
            >
              {!showPromoCodes ? "Promo Codes" : "User List"}
            </button>
            <button
              onClick={() => {
                navigate(ROUTES.PUSH_NOTIFICATIONS);
              }}
            >
              Push Notifications
            </button>
            {showPromoCodes && <PromoCodes />}
            {showUsers && <UserList />}
          </div>
        </div>
      </div>
    </section>
  );
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminPageBase);

const AdminPageFinal = () => (
  <Layout>
    <AdminPage />
  </Layout>
);

export default AdminPageFinal;
