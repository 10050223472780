import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

const codeRegex = /^[a-zA-Z0-9-]*$/;

const PromoCodeSchema = Yup.object().shape({
  code: Yup.string().matches(codeRegex, 'Only 0-9, a-z and a dash (-) can be used.').required("Required")
});

export default function PromoCodeForm({ posting, onCancel, onSubmit }) {
  // eslint-disable-next-line
  const [values, setValues] = React.useState();

  const submitClass = classNames({
    button: true,
    fancy: true,
    "is-loading": posting
  });

  return (
    <div className="promo-code-form">
      <Formik
        initialValues={{
          code: "",
          unlimited: false,
          quantity: 0,
          num_days: 0
        }}
        validationSchema={PromoCodeSchema}
        onSubmit={values => {
          setValues(values);

          onSubmit(values);
        }}
        render={props => (
          <Form>
            <label htmlFor="code">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                <strong>Code</strong>
                <ErrorMessage
                  name="code"
                  component="span"
                  className="field-error"
                />
              </div>
            </label>
            <Field
              id="code"
              aria-required="false"
              name="code"
              placeholder="KLFREEWEEK"
              type="text"
            />

            <label htmlFor="numDays">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                <strong>Number of Trials Days</strong> (7 for week, 30 for month, etc)
                <ErrorMessage
                  name="numDays"
                  component="span"
                  className="field-error"
                />
              </div>
            </label>
            <Field
              id="numDays"
              aria-required="false"
              name="numDays"
              placeholder="7"
              type="number"
              min="1"
            />

            <label htmlFor="unlimited">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                <strong>Unlimited Uses?</strong>
                <ErrorMessage
                  name="unlimited"
                  component="span"
                  className="field-error"
                />
              </div>
            </label>
            <Field
              id="unlimited"
              aria-required="false"
              name="unlimited"
              placeholder="7"
              type="checkbox"
            />

            <label htmlFor="quantity">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                <strong>Quantity</strong> (not used if unlimited)
                <ErrorMessage
                  name="quantity"
                  component="span"
                  className="field-error"
                />
              </div>
            </label>
            <Field
              id="quantity"
              aria-required="false"
              name="quantity"
              placeholder="7"
              type="number"
              min="0"
            />
            <div className="has-text-left">
              <button
                className="fancy subscribe-button"
                data-element="submit"
                disabled={posting}
                onClick={onCancel}
              >
                Cancel
              </button>{" "}
              <button
                className={submitClass}
                data-element="submit"
                type="submit"
                disabled={posting || !props.isValid}
              >
                {!posting && "Submit New Code"}
                {posting && "Submitting..."}
              </button>
            </div>
          </Form>
        )}
      />
    </div>
  );
}
