import React from "react";

const FilterButton = ({ onClick, title }) => (
  <button className="user-list-filter" onClick={onClick}>
    {title}
  </button>
);

export default function UserStatus({ users, onFilter }) {
  const adminCount = 2; //users.filter((x) => x.roles["ADMIN"]).length;
  const canceledCount = users.filter(
    (x) => x.subscription_status === "canceled"
  ).length;
  const trialingCount = users.filter(
    (x) => x.subscription_status === "trialing"
  ).length;
  const cancelingCount = users.filter(
    (x) => x.subscription_status === "canceling"
  ).length;
  const readyCount = users.filter((x) => x.subscription_status === "ready")
    .length;
  const mobileReadyCount = users.filter(
    (x) => x.subscription_status === "mobile_ready"
  ).length;
  const unpaidCount = users.filter(
    (x) =>
      x.subscription_status === "incomplete" ||
      x.subscription_status === "open" ||
      x.subscription_status === "unpaid" ||
      x.subscription_status === undefined
  ).length;
  const ambassadorCount = users.filter((x) => x.ambassador).length;

  const totalUnpaid = unpaidCount + ambassadorCount + adminCount;

  const errorCount =
    users.filter(
      (x) =>
        x.subscription_status === "incomplete" ||
        x.subscription_status === "open" ||
        x.subscription_status === undefined
    ).length - 2; // for admins

  const pastDueCount = users.filter((x) => x.subscription_status === "past_due")
    .length;
  const activeCount =
    users.filter((x) => x.subscription_status === "active").length -
    ambassadorCount -
    adminCount;
  const unpaidStatusCount = users.filter(
    (x) => x.subscription_status === "unpaid"
  ).length;

  return (
    <div className="columns">
      <div className="column is-3">
        <ul>
          <li>
            <FilterButton
              onClick={() => {
                onFilter("active");
              }}
              title="Active"
            />{" "}
            {activeCount +
              trialingCount +
              readyCount +
              mobileReadyCount +
              pastDueCount}
            <ul>
              <li>
                <FilterButton
                  onClick={() => {
                    onFilter("paying");
                  }}
                  title="Paying"
                />{" "}
                ({activeCount})
              </li>
              <li>
                <button
                  className="user-list-filter"
                  onClick={() => {
                    onFilter("trialing");
                  }}
                >
                  Trialing
                </button>{" "}
                ({trialingCount})
              </li>
              <li>
                <button
                  className="user-list-filter"
                  onClick={() => {
                    onFilter("ready");
                  }}
                >
                  Ready
                </button>{" "}
                ({readyCount})
              </li>
              <li>
                <button
                  className="user-list-filter"
                  onClick={() => {
                    onFilter("mobile_ready");
                  }}
                >
                  Mobile Ready
                </button>{" "}
                ({mobileReadyCount})
              </li>
              <li>
                <button
                  className="user-list-filter"
                  onClick={() => {
                    onFilter("past_due");
                  }}
                >
                  Past Due
                </button>{" "}
                ({pastDueCount})
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="column is-3">
        <ul>
          <li>
            <button
              className="user-list-filter"
              onClick={() => {
                onFilter("canceling");
              }}
            >
              Canceling
            </button>{" "}
            ({cancelingCount})
          </li>
        </ul>
      </div>

      <div className="column is-3">
        <ul>
          <li>
            <button
              className="user-list-filter"
              onClick={() => {
                onFilter("canceled");
              }}
            >
              Canceled
            </button>{" "}
            ({canceledCount})
          </li>
        </ul>
      </div>

      <div className="column is-3">
        <ul>
          <li>
            <button
              className="user-list-filter"
              onClick={() => {
                onFilter("not_paying");
              }}
            >
              Not Paying
            </button>{" "}
            ({totalUnpaid})
            <ul>
              <li>
                <FilterButton
                  onClick={() => {
                    onFilter("ambassadors");
                  }}
                  title="Ambassadors"
                />{" "}
                ({ambassadorCount})
              </li>
              <li>
                <FilterButton
                  onClick={() => {
                    onFilter("admins");
                  }}
                  title="Admins"
                />{" "}
                ({adminCount})
              </li>
              <li>
                <button
                  className="user-list-filter"
                  onClick={() => {
                    onFilter("incomplete");
                  }}
                >
                  Account Error
                </button>{" "}
                ({errorCount})
              </li>
              <li>
                <button
                  className="user-list-filter"
                  onClick={() => {
                    onFilter("unpaid");
                  }}
                >
                  Unpaid
                </button>{" "}
                ({unpaidStatusCount})
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
