import React, { Component } from "react";
import { FaClipboardList } from "@react-icons/all-files/fa/FaClipboardList";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { compareAsc, compareDesc, format, fromUnixTime } from "date-fns";

import UserStatus from "./UserStatus";
import { withFirebase } from "../Firebase";

const DEFAULT_FILTERS = [
  "incomplete",
  "past_due",
  "open",
  "ready",
  "mobile_ready",
  "canceling",
  "canceled",
  "trialing",
  "unpaid",
];

const SUBSCRIPTION_TYPES = {
  0: "1mo",
  1: "3mo",
  2: "6mo",
  3: "BF 1yr",
  4: "BF 6mo",
  5: "2021 1mo",
  6: "2021 3mo",
  7: "2021 6mo",
  8: "2022 1mo",
  9: "2022 3mo",
  10: "2022 6mo",
};

const APPLE_SUBSCRIPTIONS = {
  Monthly_29: "Apple 1mo",
  TriMonthly_69: "Apple 3mo",
  SemiAnnually_99: "Apple 6mo",
};

const ANDROID_SUBSCRIPTIONS = {
  "monthly_29:base-monthly": "Google 1mo",
  "trimonthly_69:base-tri-monthly": "Google 3mo",
  "semiannually_99:base-semi-annually": "Google 6mo",
};

const SortButton = ({ sortState, sortBy, onSort }) => (
  <>
    {sortBy === sortState.sortBy && sortState.asc === "asc" ? (
      <strong>Asc</strong>
    ) : (
      <button
        className="user-list-filter"
        onClick={() => {
          onSort(sortBy, "asc");
        }}
      >
        Asc
      </button>
    )}
    /
    {sortBy === sortState.sortBy && sortState.asc === "desc" ? (
      <strong>Desc</strong>
    ) : (
      <button
        className="user-list-filter"
        onClick={() => {
          onSort(sortBy, "desc");
        }}
      >
        Desc
      </button>
    )}
  </>
);

class UserList extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      filter: null,
      filteredUsers: [],
      sortBy: "created_on",
      asc: "desc",
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.unsubscribe = this.props.firebase.users().onSnapshot((snapshot) => {
        let users = [];

        snapshot.forEach((doc) => {
          users.push({ ...doc.data(), uid: doc.id });
        });

        const sortedUsers = users.sort((x, y) => y.created_on - x.created_on);
        this.setState({
          users: sortedUsers,
          filteredUsers: sortedUsers,
          loading: false,
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onFilter = (filter) => {
    // handle standard stripe statuses
    if (DEFAULT_FILTERS.includes(filter)) {
      const filteredUsers = this.state.users.filter(
        (x) => !filter || (filter && x.subscription_status === filter)
      );
      this.setState({ filter, filteredUsers });
    } else {
      // handle our derived statuses
      let filteredUsers = [];
      if (filter === "paying") {
        filteredUsers = this.state.users.filter(
          (x) =>
            x.subscription_status === "active" &&
            !x.roles["ADMIN"] &&
            !x.ambassador
        );
      } else if (filter === "not_paying") {
        filteredUsers = this.state.users.filter(
          (x) =>
            x.subscription_status === undefined ||
            x.subscription_status === "incomplete" ||
            x.subscription_status === "open" ||
            x.subscription_status === "past_due" ||
            x.roles["ADMIN"] ||
            x.ambassador
        );
      } else if (filter === "ambassadors") {
        filteredUsers = this.state.users.filter((x) => x.ambassador);
      } else if (filter === "error") {
        filteredUsers = this.state.users.filter(
          (x) =>
            x.subscription_status === undefined ||
            x.subscription_status === "incomplete" ||
            x.subscription_status === "open"
        );
      } else if (filter === "admins") {
        filteredUsers = this.state.users.filter((x) => x.roles["ADMIN"]);
      } else if (filter === "active") {
        filteredUsers = this.state.users.filter(
          (x) =>
            (x.subscription_status === "active" ||
              x.subscription_status === "trialing" ||
              x.subscription_status === "ready" ||
              x.subscription_status === "mobile_ready" ||
              x.subscription_status === "past_due") &&
            !x.roles["ADMIN"] &&
            !x.ambassador
        );
      } else if (filter === "signed_up") {
      }

      this.setState({ filter, filteredUsers });
    }
  };

  onSort = (sortBy, asc) => {
    if (sortBy === "last_accessed") {
      if (asc === "asc") {
        const filteredUsers = this.state.filteredUsers.sort((x, y) => {
          const left = new Date(x[sortBy]);
          const right = new Date(y[sortBy]);
          return compareAsc(left, right);
        });
        this.setState({ sortBy, asc, filteredUsers });
      } else {
        const filteredUsers = this.state.filteredUsers.sort((x, y) => {
          const left = new Date(x[sortBy]);
          const right = new Date(y[sortBy]);
          return compareDesc(left, right);
        });
        this.setState({ sortBy, asc, filteredUsers });
      }
    } else if (sortBy === "created_on") {
      if (asc === "desc") {
        const filteredUsers = this.state.filteredUsers.sort(
          (x, y) => y.created_on - x.created_on
        );
        this.setState({ sortBy, asc, filteredUsers });
      } else {
        const filteredUsers = this.state.filteredUsers.sort(
          (x, y) => x.created_on - y.created_on
        );
        this.setState({ sortBy, asc, filteredUsers });
      }
    }
  };

  facebookExport = () => {
    let clipboardText = "email,fn,ln,phone";
    this.state.filteredUsers.forEach((x) => {
      const nameSplit = x.username.trim().split(" ");
      const last = nameSplit[nameSplit.length - 1];
      const first = nameSplit[0];
      clipboardText = `${clipboardText}\n${
        x.email
      },${first},${last},${x.phone || ""}`;
    });
    navigator.clipboard.writeText(clipboardText);
  };

  render() {
    const { users, loading, filteredUsers } = this.state;
    const sortState = { sortBy: this.state.sortBy, asc: this.state.asc };

    return (
      <div>
        <h2>
          Users{" "}
          <small>
            <button className="fancy small" onClick={this.facebookExport}>
              Export <FaClipboardList /> <FaFacebook />
            </button>
          </small>
        </h2>
        <UserStatus users={users} onFilter={this.onFilter} />
        {this.state.filter && (
          <p>
            Viewing users with status: {this.state.filter} (
            {filteredUsers.length})
          </p>
        )}
        <div className="columns">
          <div className="column is-2">
            <span>
              <button
                className="user-list-filter"
                onClick={() => {
                  this.setState({ filter: null, filteredUsers: [...users] });
                }}
              >
                Total Users ({users.length})
              </button>
            </span>
          </div>
          <div className="column is-1">
            <span>Sort by...</span>
          </div>
          <div className="column is-2">
            <span>
              Join Date{" "}
              <SortButton
                sortState={sortState}
                onSort={this.onSort}
                sortBy="created_on"
              />
            </span>
          </div>
          {/* <div className="column is-2">
            <span>
              Name <SortButton onSort={this.onSort} sortBy="username" />
            </span>
          </div>
          <div className="column is-2">
            <span>
              Email <SortButton onSort={this.onSort} sortBy="email" />
            </span>
          </div> */}
          <div className="column is-2">
            <span>
              Last Accessed{" "}
              <SortButton
                sortState={sortState}
                onSort={this.onSort}
                sortBy="last_accessed"
              />
            </span>
          </div>
        </div>
        {loading && <div>Loading ...</div>}
        <div className="columns is-multiline">
          {filteredUsers.map((user) => {
            let statusColor = "#5cb85c";
            if (user.subscription_status === "trialing") {
              statusColor = "#448AFF";
            } else if (user.subscription_status === "canceling") {
              statusColor = "#f0ad4e";
            } else if (user.subscription_status === "canceled") {
              statusColor = "#D9534F";
            }
            const joinDate = user.created_on ? (
              format(fromUnixTime(user.created_on / 1000), "MM/dd/yyyy")
            ) : (
              <span className="field-error">Error with this account</span>
            );

            const cancelDate =
              user.subscription_status === "canceled" && user.canceled_at
                ? format(fromUnixTime(user.canceled_at), "MM/dd/yyyy")
                : "";

            const periodEnds = user.current_period_end
              ? format(fromUnixTime(user.current_period_end), "MM/dd/yyyy")
              : null;

            const showReason =
              user.subscription_status === "canceling" ||
              user.subscription_status === "canceled";

            const instagramLinkUser =
              user.instagram && user.instagram.indexOf("@") === 0
                ? user.instagram.substring(1)
                : user.instagram;

            let subscriptionType = "Unknown";
            if (user.subscriptionType !== undefined) {
              subscriptionType =
                SUBSCRIPTION_TYPES[user.subscriptionType] ||
                APPLE_SUBSCRIPTIONS[user.subscriptionType] ||
                ANDROID_SUBSCRIPTIONS[user.subscriptionType];
            }

            return (
              <div className="column is-4" key={user.uid}>
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">
                      {user.username},
                      <span style={{ backgroundColor: statusColor }}>
                        <strong>
                          {user.subscription_status &&
                            user.subscription_status.toUpperCase()}
                        </strong>
                      </span>{" "}
                    </p>
                  </header>
                  <div className="card-content">
                    <div className="content">
                      <strong>Subscription Type</strong> {subscriptionType} (
                      {user.subscriptionType})
                      <br />
                      <strong>Email</strong> {user.email}
                      <br />
                      <strong>Phone</strong> {user.phone}
                      <br />
                      {user.instagram && (
                        <>
                          <strong>Instagram</strong>{" "}
                          <a
                            href={`https://instagram.com/${instagramLinkUser}`}
                          >
                            {instagramLinkUser}
                          </a>
                          <br />
                        </>
                      )}
                      <strong>Stripe ID</strong> {user.stripe_id}
                      <br />
                      <strong>DB ID</strong> {user.uid}
                      <br />
                      <strong>PromoCode</strong> {user.promoCode}
                      <br />
                      {user.signupReason && (
                        <>
                          <strong>Discovered via </strong> {user.signupReason}{" "}
                          {user.reasonMember && `(${user.reasonMember})`}
                          <br />
                        </>
                      )}
                      {showReason && (
                        <>
                          <strong>Cancel Reason</strong> {user.cancel_reason}{" "}
                          {user.cancel_other || ""}
                          <br />
                        </>
                      )}
                      {user.presale && (
                        <strong>
                          Early Signup
                          <br />
                        </strong>
                      )}
                      {cancelDate.length > 0 && (
                        <>
                          <br />
                          <strong>Canceled</strong> <time>{cancelDate}</time>
                        </>
                      )}
                      <br />
                      <strong>Joined</strong> <time>{joinDate}</time>
                      <br />
                      <strong>Period Ends</strong> <time>{periodEnds}</time>
                      <br />
                      <strong>Last Accessed</strong>{" "}
                      {user.last_accessed ? (
                        <time dateTime={user.last_accessed}>
                          {format(
                            new Date(user.last_accessed),
                            "MM/dd/yyyy hh:mm a"
                          )}
                        </time>
                      ) : (
                        "Unknown"
                      )}
                      {user.free_pep_talk_used && (
                        <strong>
                          <br />
                          Used Free Peptalk
                        </strong>
                      )}
                    </div>
                  </div>
                  {/*<footer className="card-footer">
                    <a href="#" className="card-footer-item">
                      Save
                    </a>
                    <a href="#" className="card-footer-item">
                      Edit
                    </a>
                    <a href="#" className="card-footer-item">
                      Delete
                    </a>
            </footer>*/}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withFirebase(UserList);
