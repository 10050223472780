import React from "react";
import PromoCodeForm from "./PromoCodeForm";
import { withFirebase } from "../Firebase";

function PromoCode({ code }) {
  return (
    <div>
      <strong>{code.id.toUpperCase()}</strong> - # used: {code.used}
      {code.deleted && (
        <>
          , <span className="deleted-text">DELETED</span>
        </>
      )}
    </div>
  );
}

function PromoCodes({ firebase }) {
  const [loaded, setLoaded] = React.useState(false);
  const [posting, setPosting] = React.useState(false);
  const [unsubscribe, setUnsubscribe] = React.useState();
  const [promoCodes, setPromoCodes] = React.useState([]);
  const [showNew, setShowNew] = React.useState(false);
  const [showDeleted, setShowDeleted] = React.useState(false);

  React.useEffect(() => {
    if (firebase && !loaded) {
      const unsub = firebase
        .promoCodes()
        .orderBy("created_on", "desc")
        .onSnapshot((snapshot) => {
          let dbPromoCodes = [];

          snapshot.forEach((doc) => {
            dbPromoCodes.push({ ...doc.data(), id: doc.id });
          });

          setPromoCodes(dbPromoCodes);
          setLoaded(true);
          setUnsubscribe(unsub);
        });
    }
    return () => unsubscribe;
  }, [firebase, loaded, unsubscribe]);

  function onSubmit(data) {
    setPosting(true);

    const { code, numDays: num_days, unlimited, quantity } = data;

    const upperCode = code.toUpperCase();

    const now = new Date();
    const created_on = Math.round(now.getTime() / 1000);

    const newPromoCode = {
      code: upperCode,
      created_on,
      deleted: false,
      deleted_on: null,
      discount: null,
      expires_on: null,
      num_days,
      unlimited,
      quantity,
      used: 0,
    };

    firebase
      .promoCodes()
      .doc(upperCode)
      .set({ ...newPromoCode })
      .then((promoCodeRef) => {
        setPosting(false);
        setShowNew(false);
        setPromoCodes([...promoCodes, { id: upperCode, ...newPromoCode }]);
      });
  }

  if (!loaded) {
    return <div>Loading promo code data...</div>;
  }

  if (showNew) {
    return (
      <PromoCodeForm
        onCancel={() => {
          setShowNew(false);
        }}
        onSubmit={onSubmit}
        posting={posting}
      />
    );
  }

  return (
    <div>
      <div>{promoCodes.length} promocodes</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          onClick={() => {
            setShowNew(true);
          }}
        >
          Add New PromoCode
        </button>

        <button
          onClick={() => {
            setShowDeleted(!showDeleted);
          }}
        >
          {showDeleted
            ? "Hide Deleted"
            : `Show Deleted (${
                promoCodes.filter((code) => code.deleted).length
              })`}
        </button>
      </div>
      <div>
        {promoCodes
          .filter((code) => {
            if (showDeleted) {
              return code;
            }

            return !code.deleted;
          })
          .map((code) => (
            <PromoCode key={code.code} code={code} />
          ))}
      </div>
    </div>
  );
}

export default withFirebase(PromoCodes);
